<template>
  <section
    :style="{
      height: stlye.height,
      width: stlye.width,
    }"
  >
    <div :id="id" style="height: 100%; width: 100%"></div>
  </section>
</template>
<script>
export default {
  props: ["id", "stlyedata", "data"],
  data() {
    return {
      optionData: {},
    };
  },
  created() {},

  methods: {
    line_chart() {
      let myChart = this.$echarts.init(document.getElementById(this.id));
      // console.log(this.option, this.id, "this.option");
      let _this = this;
      let colorList = this.option.colorList;

      // 橙色
      let orgIcon =
        '<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.719 .164)" fill="none" fill-rule="evenodd"><circle stroke="#D36218" fill="#323538" cx="4.833" cy="4.833" r="4.333"/><circle fill="#D36218" cx="4.833" cy="4.833" r="1.758"/></g></svg>';
      orgIcon = encodeURIComponent(orgIcon); // 转译
      orgIcon = "data:image/svg+xml;utf8," + orgIcon; // 添加url前缀
      orgIcon = "image://" + orgIcon; // 添加ECharts需要的前缀
      // 蓝色
      let blueIcon =
        '<svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.719 .564)" fill="none" fill-rule="evenodd"><circle stroke="#57F7FF" fill="#323538" cx="4.833" cy="4.833" r="4.333"/><circle fill="#57F7FF" cx="4.833" cy="4.833" r="1.758"/></g></svg>';
      blueIcon = encodeURIComponent(blueIcon); // 转译
      blueIcon = "data:image/svg+xml;utf8," + blueIcon; // 添加url前缀
      blueIcon = "image://" + blueIcon; // 添加ECharts需要的前缀
      // 灰色
      let greyIcon =
        '<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.448 .335)" fill="none" fill-rule="evenodd"><circle stroke="#C7C8C7" fill="#323538" cx="4.833" cy="4.833" r="4.333"/><circle fill="#C7C8C7" cx="4.833" cy="4.833" r="1.758"/></g></svg>';
      greyIcon = encodeURIComponent(greyIcon); // 转译
      greyIcon = "data:image/svg+xml;utf8," + greyIcon; // 添加url前缀
      greyIcon = "image://" + greyIcon; // 添加ECharts需要的前缀
      let iconList = [];
      if (_this.id == "historyEcharts") {
        iconList = [blueIcon, orgIcon, greyIcon];
      } else {
        iconList = [blueIcon, orgIcon];
      }
      let arr = this.option.data;
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.option.name,
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function (arg) {
            if (_this.id == "historyEcharts") {
              let html = arg.name + "<br>";
              html += "在线数:" + arr[0].data[arg["dataIndex"]] + "<br>";
              html += "故障数:" + arr[1].data[arg["dataIndex"]] + "<br>";
              html += "离线数:" + arr[2].data[arg["dataIndex"]] + "<br>";
              return html;
            } else {
              console.log(arg, _this.option, "_this.option");
              let html = arg.name + "<br>";
              html +=
                "提交数:" +
                _this.option.submitNumber[arg["dataIndex"]] +
                "<br>";
              html +=
                "处理数:" +
                _this.option.data[0].data[arg["dataIndex"]] +
                "<br>";
              html +=
                "验收数:" +
                _this.option.data[1].data[arg["dataIndex"]] +
                "<br>";
              html +=
                "处理率:" +
                _this.option.data[0].disposeProportion[arg["dataIndex"]] +
                "%<br>";
              html +=
                "验收率:" +
                _this.option.data[1].acceptanceProportion[arg["dataIndex"]] +
                "%<br>";
              return html;
            }
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "20%",
          top: "10%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
          // min: 0,
          // max: function (value) {
          //   return value.max + 50;
          // },
          // splitNumber: 3,
        },
        series: (function () {
          let data = [];
          if (arr && arr.length > 0) {
            arr.forEach((item, i) => {
              let arr = {
                data: item.data,
                type: "line",
                label: {
                  normal: {
                    show: false,
                    color: "#fff",
                    position: "top",
                  },
                },
                lineStyle: {
                  normal: {
                    color: colorList[i],
                    type: "dotted", //'dotted'虚线 'solid'实线
                  },
                },
                smooth: false,
                symbolSize: 10,
                symbol: iconList[i],
              };
              data.push(arr);
            });
          }

          return data;
        })(),
        color: ["rgba(211,98,24,1)"],
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.line_chart();
    });
  },
  watch: {
    data: {
      handler: function () {
        this.line_chart();
      },
      deep: true,
    },
  },
  computed: {
    stlye() {
      return this.stlyedata;
    },
    option() {
      return this.data;
    },
  },
};
</script>