<template>
  <section
    :style="{
      height: stlye.height,
      width: stlye.width,
    }"
  >
    <div :id="id" style="height: 100%; width: 100%"></div>
  </section>
</template>
<script>
export default {
  props: ["id", "stlyedata", "data"],
  data() {
    return {};
  },
  created() {},

  methods: {
    bar_chart() {
      let myChart = this.$echarts.init(document.getElementById(this.id));
      let _this = this;

      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (arg) {
            if (_this.id == "deviceTypEecharts") {
              let html = _this.option.dateTime + "<br>";
              html +=
                arg.name +
                "数量:" +
                _this.option.data[arg["dataIndex"]] +
                "<br>";
              html +=
                arg.name +
                "占比:" +
                _this.option.site[arg["dataIndex"]] +
                "%<br>";

              return html;
            } else if (_this.id == "getWarningLevelId") {
              let html = _this.option.time[arg["dataIndex"]] + "<br>";
              html +=
                _this.option.name[arg["dataIndex"]] +
                "数:" +
                _this.option.data[arg["dataIndex"]] +
                "<br>";
              html +=
                _this.option.name[arg["dataIndex"]] +
                "占比:" +
                _this.option.typeProportion[arg["dataIndex"]] +
                "%<br>";

              return html;
            }
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "5%",
          top: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          splitLine: { show: false },
          axisLabel: {
            show: false,
            textStyle: {
              color: "red",
            },
          },
          axisLine:{
             show: false,
          },
             axisTick: {
        show: false
    }

        },

        yAxis: {
          type: "category",
          data: this.option.name,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          axisLine:{
             show: false,
          },
             axisTick: {
        show: false
    }
        },
        series: [
          {
            name: "搜索引擎",
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "insideRight",
            },
            data: this.option.data,
            itemStyle: {
              //通常情况下：
              normal: {
                //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                color: function (params) {
                  var colorList = [
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                  ]; //每根柱子的颜色
                  var colorList1 = [
                    /* "rgba(255,86,79,1)",
                    "rgba(255,122,64,1)",
                    "rgba(220,164,54,1)", */
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                    "#0092FF",
                  ];
                  return _this.id == "getWarningLevelId"
                    ? colorList1[params.dataIndex]
                    : colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.bar_chart();
    });
  },
  watch: {
    data: {
      handler: function () {
        this.bar_chart();
      },
      deep: true,
    },
  },
  computed: {
    stlye() {
      return this.stlyedata;
    },
    option() {
      return this.data;
    },
  },
};
</script>