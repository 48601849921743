<template>
  <section>
    <a-row type="flex" justify="space-between" class="container">
      <a-col style="height: 100%; width: 29%">
        <a-row type="flex" justify="space-between" style="height: 100%">
          <boxTitleMoudle
            class="weather_box m-b-18 weather"
            style="height: 47.5%; width: 100%"
            :left="'0px'"
            :title="'状态统计'"
          >
            <!-- 实时数据 -->
            <section class="realTime" style="height: 50%">
              <img
                class="title_bg"
                src="../assets/image/bg-image/realTime.png"
                alt=""
              />
              <section class="des">单位/台</section>
              <a-row type="flex" justify="space-between" style="height: 90%">
                <a-col :span="8">
                  <div id="realTime_1" style="width: 100%; height: 100%"></div>
                </a-col>
                <a-col :span="8">
                  <div id="realTime_2" style="width: 100%; height: 100%"></div>
                </a-col>
                <a-col :span="8">
                  <div id="realTime_3" style="width: 100%; height: 100%"></div>
                </a-col>
              </a-row>
            </section>

            <!-- 历史趋势 -->
            <section class="history" style="height: 50%">
              <img
                class="title_bg"
                src="../assets/image/bg-image/history.png"
                alt=""
              />
              <section class="des">
                <section class="status_list">
                  <span class="echarts_title onLine">在线</span>
                  <span class="echarts_title fix">故障</span>
                  <span class="echarts_title outLine">离线</span>

                  <span> 单位/台</span>
                </section>
              </section>
              <a-row type="flex" justify="space-between" style="height: 90%">
                <line-Chart
                  :id="historyEcharts"
                  :stlyedata="stlyedata"
                  :data="historyEchartsData"
                ></line-Chart>
                <!-- <div id="history_echarts" style="width:100%;height:100%"></div> -->
              </a-row>
            </section>
          </boxTitleMoudle>

          <boxTitleMoudle
            :title="'设备统计'"
            :left="'0px'"
            class="weather_box weather"
            style="height: 47.5%"
          >
            <!-- 设备类型 -->
            <section class="device_type" style="height: 40%">
              <img
                class="title_bg"
                src="../assets/image/bg-image/deviceType.png"
                alt=""
              />
              <section class="des">单位/台</section>

              <a-row type="flex" justify="space-between" style="height: 80%">
                <!-- <div
                  id="deviceType_echarts"
                  style="width:100%;height:80%"
                ></div> -->
                <barYchart
                  :id="deviceTypEecharts"
                  :stlyedata="deviceTypStlyedata"
                  :data="deviceTypEechartsData"
                ></barYchart>
              </a-row>
            </section>
            <!-- 设备异常 -->
            <section class="unitException m-t-16" style="height: 58%">
              <img
                class="title_bg"
                src="../assets/image/bg-image/unitException.png"
                alt=""
              />
              <a-row type="flex" justify="space-between" style="height: 80%">
                <section style="height: 100%; width: 100%">
                  <div style="width: 100%; text-align: center; height: 100%">
                    <li
                      class="flex-bet"
                      style="
                        color: #95adce;
                        display: flex;
                        justify-content: space-between;
                        text-align: center;
                      "
                    >
                      <span style="width: 50%">预警设备</span>
                      <span style="width: 20%">异常状态</span>
                      <span style="width: 30%">累计时长</span>
                    </li>
                    <div style="overflow: hidden; height: 90%">
                      <div
                        ref="contlist"
                        id="contBox"
                        class="contBox"
                        v-on:mouseover="addActive($event)"
                        v-on:mouseout="removeActive($event)"
                      >
                        <li
                          class="flex-bet safeData"
                          v-for="(item, index) in getAbnormalRecordsData"
                          :key="index"
                        >
                          <span style="width: 50%">{{ item.deviceName }}</span>
                          <span style="width: 20%">{{
                            item.status == '1' ? '在线' : '离线'
                          }}</span>
                          <span style="width: 30%">{{ item.dateDay }}</span>
                        </li>
                      </div>
                    </div>
                  </div>
                </section>
              </a-row>
            </section>
          </boxTitleMoudle>
        </a-row>
      </a-col>

      <a-col style="height: 100%; width: 41%">
        <boxTitleMoudle
          class="monitor"
          style="height: 55%"
          :title="'工改商工地项目1123'"
          :left="'0px'"
        >
          <section style="height: 95%">
            <div class="flex_bet m-t-10" style="">
              <div class="tab_li" style="padding: 0px">
                <span
                  :class="currentTab == index ? 'active' : ''"
                  v-for="(item, index) in tabList"
                  :key="index"
                  @click="changeTab(index)"
                  >{{ item }}</span
                >
              </div>
              <div class="tab_li" style="padding: 0px; width: 40%">
                <a-range-picker
                  class="model_picker"
                  size="small"
                  style="margin-right: 0px"
                  @change="onChange"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
                <br />
              </div>
            </div>
            <!-- 工地 -->
            <div
              class="banner m-t-10"
              style="height: 90%"
              v-if="currentTab == '0'"
            >
              <img
                style="height: 100%; width: 100%"
                src="../assets/image/bg-image/banner.png"
                alt
              />
              <div class="monitor monitor_1">
                <img
                  style="width: 64px"
                  src="../assets/image/bg-image/movie.png"
                  alt
                  class="movie"
                />
                <img
                  style="width: 124px"
                  src="../assets/image/bg-image/movie_status.png"
                  alt
                  class="movie_status"
                />
                <img
                  style="width: 72px; height: 90px"
                  src="../assets/image/bg-image/movieLine.png"
                  alt
                />
              </div>
              <div class="monitor monitor_2">
                <img
                  style="width: 64px"
                  src="../assets/image/bg-image/movie.png"
                  alt
                  class="movie"
                />
                <img
                  style="width: 124px"
                  src="../assets/image/bg-image/movie_status.png"
                  alt
                  class="movie_status"
                />
                <img
                  style="width: 72px; height: 90px"
                  src="../assets/image/bg-image/movieLine.png"
                  alt
                />
              </div>
              <div class="monitor monitor_3">
                <img
                  style="width: 64px"
                  src="../assets/image/bg-image/movie.png"
                  alt
                  class="movie"
                />
                <img
                  style="width: 124px"
                  src="../assets/image/bg-image/movie_status.png"
                  alt
                  class="movie_status"
                />
                <img
                  style="width: 72px; height: 90px"
                  src="../assets/image/bg-image/movieLine.png"
                  alt
                />
              </div>
            </div>
          </section>
        </boxTitleMoudle>

        <boxTitleMoudle :left="'0px'" :title="'设备预警'" class="workingWarm">
          <section class="warmList">
            <li v-for="(item, index) in getWarningNumberData" :key="index">
              <section>
                <span
                  class="m-b-10 digital"
                  :style="{
                    color: index == '2' || index == '4' ? '#fff' : '#ff5858'
                  }"
                  >{{ item.number }}</span
                >
                <p class="m-b-10">{{ item.name }}</p>
              </section>
            </li>
          </section>

          <section class="warmList_table">
            <a-row type="flex" justify="space-between" style="height: 80%">
              <section style="height: 100%; width: 100%">
                <div style="width: 100%; text-align: center; height: 100%">
                  <li
                    class="flex-bet"
                    style="
                      color: #95adce;
                      display: flex;
                      justify-content: space-between;
                      text-align: center;
                    "
                  >
                    <span style="width: 15%">事件名称</span>
                    <span style="width: 25%">事件描述</span>
                    <span style="width: 20%">预警设备</span>
                    <span style="width: 20%">预警时间</span>
                    <span style="width: 20%">预警地点</span>
                  </li>
                  <div style="overflow: hidden; height: 90%">
                    <div
                      ref="contwarmlist"
                      id="warmcontBox"
                      class="contBox"
                      v-on:mouseover="addWarmActive($event)"
                      v-on:mouseout="removeWarmActive($event)"
                    >
                      <li
                        class="flex-bet safeData"
                        v-for="(item, index) in getInOutAlarmRecordListData"
                        :key="index"
                      >
                        <span
                          class="text-overflow"
                          :title="item.alarmName"
                          style="width: 15%"
                          >{{ item.alarmName }}</span
                        >
                        <span
                          class="text-overflow"
                          :title="item.alarmMessage"
                          style="width: 25%"
                          >{{ item.alarmMessage }}</span
                        >
                        <span
                          class="text-overflow"
                          :title="item.deviceName"
                          style="width: 20%"
                          >{{ item.deviceName }}</span
                        >
                        <span
                          class="text-overflow"
                          :title="item.createTime"
                          style="width: 20%"
                          >{{ item.createTime }}</span
                        >
                        <span
                          class="text-overflow"
                          :title="item.alarmArea"
                          style="width: 20%"
                          >{{ item.alarmArea }}</span
                        >
                      </li>
                    </div>
                  </div>
                </div>
              </section>
            </a-row>
          </section>
        </boxTitleMoudle>
      </a-col>
      <a-col style="height: 100%; width: 29%">
        <boxTitleMoudle
          :left="'0px'"
          :title="'预警统计'"
          class="green"
          style="height: 47.5%"
        >
          <!-- 预警类型 -->
          <section style="height: 47.5%" class="warm_type">
            <section>
              <img
                class="title_bg"
                src="../assets/image/bg-image/warm_type.png"
                alt=""
              />
              <section class="des">单位/次</section>
            </section>
            <div
              id="warm_type_eacharts"
              style="width: 100%; height: 100%"
            ></div>
          </section>
          <!-- 预警等级 -->
          <section style="height: 47.5%" class="warm_level">
            <section style="height: 100%">
              <img
                class="title_bg"
                src="../assets/image/bg-image/warm_level.png"
                alt=""
              />
              <section class="des">单位/数</section>

              <!-- <div id="warm_level_eacharts" style="width:100%;height:70%"></div> -->
              <barYchart
                :id="getWarningLevelId"
                :stlyedata="levelStlyedata"
                :data="getWarningLevelData"
              ></barYchart>
            </section>
          </section>
        </boxTitleMoudle>
        <boxTitleMoudle
          :left="'0px'"
          :title="'预警统计'"
          class="record"
          style="height: 47.5%"
        >
          <!-- 维保情况 -->
          <section style="height: 42.5%" class="fix_situation">
            <section style="height: 100%">
              <img
                class="title_bg"
                src="../assets/image/bg-image/fix_situation.png"
                alt=""
              />

              <section class="des">单位/次</section>
              <div
                id="fix_situation_eacharts"
                style="width: 100%; height: 90%"
              ></div>
            </section>
          </section>

          <!-- 维保历史 -->
          <section style="height: 52.5%" class="fix_history">
            <section style="height: 100%">
              <img
                class="title_bg"
                src="../assets/image/bg-image/fix_history.png"
                alt=""
              />

              <section class="des">
                <section class="status_list">
                  <span class="echarts_title onLine">处理率</span
                  ><span class="echarts_title fix">验收率</span>
                  <span>单位/次</span>
                </section>
              </section>

              <!-- <div
                id="fix_history_eacharts"
                style="width:100%;height:90%"
              ></div> -->
              <line-Chart
                :id="fixHistoryEcharts"
                :stlyedata="fixHistorystlyedata"
                :data="fixHistoryEchartsData"
              ></line-Chart>
            </section>
          </section>
        </boxTitleMoudle>
      </a-col>
    </a-row>
  </section>
</template>
<script>
  import lineChart from '@/components/echarts_list/lineChart.vue'
  import barYchart from '@/components/echarts_list/barYchart.vue'
  import boxTitleMoudle from '@/components/BaseBoxTitleMoudle.vue'
  export default {
    data() {
      return {
        tabList: ['工地', '项目预览', '宣传快讯', '通知公告'],
        currentTab: '0',
        // 历史趋势
        historyEcharts: 'historyEcharts',
        stlyedata: {
          width: '100%',
          height: '90%'
        },

        historyEchartsData: {},
        // 维保历史
        fixHistoryEcharts: 'fixHistoryEcharts',
        fixHistorystlyedata: {
          width: '100%',
          height: '90%'
        },

        fixHistoryEchartsData: {},
        // 设备类型
        deviceTypEecharts: 'deviceTypEecharts',
        deviceTypStlyedata: {
          width: '100%',
          height: '80%'
        },

        deviceTypEechartsData: {},
        // 预警等级
        getWarningLevelId: 'getWarningLevelId',
        levelStlyedata: {
          width: '100%',
          height: '70%'
        },

        getWarningLevelData: {},

        // 设备异常记录
        getAbnormalRecordsData: [],
        // 设备预警
        getWarningNumberData: [],
        // 设备预警记录
        getInOutAlarmRecordListData: [],
        scrollTime: null,
        distance: 0,
        scrollWarmTime: null,
        Warmdistance: 0
      }
    },
    components: {
      lineChart,
      barYchart,
      boxTitleMoudle
    },
    created() {
      // 物联大屏-设备状态统计
      this.getStatusStatistical()
      // 设备历史趋势
      this.getDevStatusTrend()
      // 设备类型统计
      this.getTypeStatistical()
      // 设备异常记录
      this.getAbnormalRecords()
      //设备预警
      this.getWarningNumber()
      // 设备预警记录
      this.getInOutAlarmRecordList()
      // 预警类型
      this.getWarningType()
      // 预警等级
      this.getWarningLevel()
      // 物联大屏-维保统计
      this.getMaintenanceStatistical()
    },
    mounted() {
      // 预警类型
      // this.realTime_type("warm_type_eacharts", "在线");
      // 维保历史
      // this.history_echarts("fix_history_eacharts");
      // 设备类型
      //this.deviceType_echarts("deviceType_echarts");
      // 预警等级
      //  this.deviceType_echarts("warm_level_eacharts");
      // 维保情况
      // this.trapezoid_chart();
    },
    methods: {
      // 设备异常移入
      addActive() {
        clearInterval(this.scrollTime)
      },
      //  设备异常移出
      removeActive() {
        this.ScrollUp()
      },
      //  设备异常
      ScrollUp() {
        let offsetHeight = this.$refs.contlist.offsetHeight
        let contBox = document.getElementById('contBox')
        //设置位移
        this.scrollTime = setInterval(() => {
          let distanceData = this.distance - 10
          this.distance = distanceData
          // 如果位移超过文字宽度，则回到起点
          if (-distanceData >= offsetHeight) {
            this.distance = 0
            distanceData = 0
          }
          contBox.style.transform = 'translateY(' + distanceData + 'px)'
        }, 3000)
      },
      // 设备预警移入
      addWarmActive() {
        clearInterval(this.scrollWarmTime)
      },
      //  设备预警移出
      removeWarmActive() {
        this.WarmScrollUp()
      },
      //  设备预警
      WarmScrollUp() {
        let offsetHeight = this.$refs.contwarmlist.offsetHeight
        let contBox = document.getElementById('warmcontBox')
        //设置位移
        this.scrollWarmTime = setInterval(() => {
          let distanceData = this.Warmdistance - 10
          this.Warmdistance = distanceData
          // 如果位移超过文字宽度，则回到起点
          if (-distanceData >= offsetHeight) {
            this.Warmdistance = 0
            distanceData = 0
          }
          contBox.style.transform = 'translateY(' + distanceData + 'px)'
        }, 3000)
      },
      // 物联大屏-设备状态统计
      getStatusStatistical() {
        this.$api.getStatusStatistical().then((res) => {
          if (res.code == '200') {
            let data = res.data
            let dateTime = res.data.dateTime
            let onlineProportion = [
              {
                value: data.onlineProportion,
                name: data.onlineProportion + '%'
              },
              {
                value: 100 - data.onlineProportion,
                name: 100 - data.onlineProportion + '%'
              }
            ]
            let onlineNumber = data.onlineNumber
            let faultProportion = [
              { value: data.faultProportion, name: data.faultProportion + '%' },
              {
                value: 100 - data.faultProportion,
                name: 100 - data.faultProportion + '%'
              }
            ]
            let faultNumber = data.faultNumber
            let offlineProportion = [
              {
                value: data.offlineProportion,
                name: data.offlineProportion + '%'
              },
              {
                value: 100 - data.offlineProportion,
                name: 100 - data.offlineProportion + '%'
              }
            ]
            let offlineNumber = data.offlineNumber
            // 实时状态
            this.realTime_type(
              'realTime_1',
              '在线',
              onlineNumber,
              onlineProportion,
              dateTime,
              ['rgba(48,204,49,1)', 'rgba(151,254,247,1)']
            )
            this.realTime_type(
              'realTime_2',
              '故障',
              faultNumber,
              faultProportion,
              dateTime,
              ['rgba(255,131,60,1)', 'rgba(151,254,247,1)']
            )
            this.realTime_type(
              'realTime_3',
              '离线',
              offlineNumber,
              offlineProportion,
              dateTime,
              ['rgba(93,62,255,1)', 'rgba(151,254,247,1)']
            )
          }
        })
      },
      //  历史趋势
      getDevStatusTrend() {
        this.$api.getDevStatusTrend().then((res) => {
          if (res.code == '200') {
            let data = res.data
            let params = {
              name: [],
              data: [],
              colorList: ['#57f7ff', '#d36218', '#c7c7c7']
            }
            if (data.length > 0) {
              let onlineObj = {
                data: [],
                name: '在线数量'
              }
              let offlineObj = {
                data: [],
                name: '离线数量'
              }
              let faultObj = {
                data: [],
                name: '故障数量'
              }
              data.forEach((item) => {
                params.name.push(item.dateTime)
                onlineObj.data.push(item.onlineNumber)
                offlineObj.data.push(item.offlineNumber)
                faultObj.data.push(item.faultNumber)
              })
              params.data.push(onlineObj)
              params.data.push(offlineObj)
              params.data.push(faultObj)

              this.historyEchartsData = params
            }
          }
        })
      },
      // 设备类型统计
      getTypeStatistical() {
        this.$api.getTypeStatistical().then((res) => {
          if (res.code == '200') {
            console.log(res)
            let data = res.data
            let params = {
              name: ['环境', '道闸', '门禁', '塔吊'],
              data: [
                data.environmentNumber,
                data.barrierGateNumber,
                data.entranceNumber,
                data.towerCraneNumber
              ],
              site: [
                data.environment,
                data.barrierGate,
                data.entrance,
                data.towerCrane
              ],
              dateTime: data.dateTime
            }

            this.deviceTypEechartsData = params
          }
        })
      },
      // 设备异常记录
      getAbnormalRecords() {
        this.$api
          .getAbnormalRecords()
          .then((res) => {
            if (res.code == '200') {
              this.getAbnormalRecordsData = res.data
            }
          })
          .then(() => {
            this.ScrollUp()
          })
      },
      // 设备预警
      getWarningNumber() {
        this.$api.getWarningNumber().then((res) => {
          if (res.code == '200') {
            console.log(res)
            this.getWarningNumberData = res.data
          }
        })
      },
      // 设备预警几率
      getInOutAlarmRecordList() {
        this.$api
          .getInOutAlarmRecordList({ type: '3' })
          .then((res) => {
            if (res.code == '200') {
              this.getInOutAlarmRecordListData = res.data
            }
          })
          .then(() => {
            this.WarmScrollUp()
          })
      },
      // 预警类型统计
      getWarningType() {
        this.$api.getWarningType().then((res) => {
          if (res.code == '200') {
            let total = 0
            let params = []
            res.data.forEach((item) => {
              total += item.number
              let arr = {
                value: item.typeProportion,
                number: item.number,
                name: item.typeName + item.typeProportion + '%',
                time: item.date
              }
              params.push(arr)
            })

            this.realTime_type('warm_type_eacharts', '预警设备', total, params)
          }
        })
      },
      // 预警等级
      getWarningLevel() {
        this.$api.getWarningLevel().then((res) => {
          if (res.code == '200') {
            let params = {
              name: [],
              data: [],
              time: [],
              typeProportion: []
            }
            res.data.forEach((item) => {
              params.name.push(item.typeName)
              params.data.push(item.number)
              params.time.push(item.date)
              params.typeProportion.push(item.typeProportion)
            })
            this.getWarningLevelData = params
          }
        })
      },

      // 维保统计
      getMaintenanceStatistical() {
        this.$api.getMaintenanceStatistical().then((res) => {
          if (res.code == '200') {
            console.log(res)

            let data = res.data.list
            let params = {
              name: [],
              data: [],
              submitNumber: [],
              colorList: ['#57f7ff', '#d36218']
            }
            if (data.length > 0) {
              let disposeObj = {
                data: [],
                disposeProportion: [],
                name: '处理率'
              }
              let acceptanceObj = {
                data: [],
                acceptanceProportion: [],
                name: '验收率'
              }

              data.forEach((item) => {
                params.name.push(item.dateTime)
                params.submitNumber.push(item.submitNumber)
                disposeObj.data.push(item.disposeNumber)
                disposeObj.disposeProportion.push(item.disposeProportion)
                acceptanceObj.data.push(item.acceptanceNumber)
                acceptanceObj.acceptanceProportion.push(
                  item.acceptanceProportion
                )
              })
              params.data.push(disposeObj)
              params.data.push(acceptanceObj)
              console.log(params, 'params')
              this.fixHistoryEchartsData = params

              let situationList = res.data.situationList
              let arr = []
              let proportion = []
              let dateTime = []
              situationList.forEach((item) => {
                arr.push(item.number)
                proportion.push(item.proportion)
                dateTime.push(item.dateTime)
              })
              this.trapezoid_chart(arr, proportion, dateTime)
            }
          }
        })
      },
      onChange() {},
      changeTab(key) {
        console.log(key)
        this.currentTab = key
      },

      // 实时状态
      realTime_type(id, title, num, proportion, dateTime, list) {
        let myChart = this.$echarts.init(document.getElementById(id))
        console.log(proportion, 'proportion')
        // 绘制图表
        //   let bottomTitle = {
        //     text: "262",
        //     textStyle: {
        //       color: "#fff",
        //       fontSize: 14,
        //       align: "center"
        //     }
        //   };
        let data = []

        proportion.forEach((item) => {
          let arr = {
            value: item.value,
            name: item.name,
            number: item.number ? item.number : '',
            time: item.time ? item.time : ''
          }
          data.push(arr)
        })

        // let data = [
        //   { value: proportion, name: proportion + "%" }
        //   // { value: (100 - proportion), name: 100 - proportion + "%" }
        // ];
        let topTitle = {
          text: title,
          textStyle: {
            fill: '#fff',
            fontSize: 12
          }
        }
        myChart.setOption({
          title: {
            text: num,
            left: 'center',
            top: '30%',
            textStyle: {
              color: '#fff',
              fontSize: 14,
              align: 'center'
            }
          },

          tooltip: {
            trigger: 'item',
            formatter: function(arg) {
              if (id != 'warm_type_eacharts') {
                let html = dateTime + '<br>'
                html += arg.seriesName + '数:' + num + '<br>'
                html += arg.seriesName + '占比:' + arg.data.name + '<br>'
                return arg.dataIndex == '1' ? '' : html
              } else {
                let html = arg.data.time + '<br>'
                html += arg.seriesName + '数:' + arg.data.number + '<br>'
                html += arg.seriesName + '占比:' + arg.data.value + '%<br>'
                return html
              }
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '25%',
            style: {
              text: topTitle.text,
              textAlign: 'center',
              fill: '#fff',
              fontSize: '12'
              //fontWeight: 500,
            }
          },
          series: [
            {
              name: title,
              type: 'pie',
              radius: ['40%', '50%'],
              center: ['50%', '30%'],
              color: list
                ? list
                : [
                    'rgba(242,194,1,1)',
                    'rgba(0,146,255,1)',
                    'rgba(185,226,242,1)'
                  ],
              avoidLabelOverlap: false,
              labelLine: {
                normal: {
                  length: 1
                }
              },

              graphic: {
                type: 'text',
                left: 'center',
                top: '45%',
                style: {
                  text: 'topTitle',
                  textAlign: 'center',
                  fill: '#fff',
                  fontSize: '12'
                  //fontWeight: 500,
                }
              },
              label: (function() {
                let obj = {
                  show: true,
                  position: 'center',
                  normal: {
                    // formatter(v) {
                    //   let text = v.name;
                    //   return text.length < 2
                    //     ? text
                    //     : `${text.slice(0, 2)}\n${text.slice(2)}`;
                    // }
                  }
                }
                let Obj = {
                  show: false,
                  position: 'center'
                }
                return id != 'warm_type_eacharts' ? Obj : obj
              })(),
              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: "30",
              //     fontWeight: "bold"
              //   }
              // },

              data: data
            }
          ]
        })

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      },
      //   历史趋势
      history_echarts(id) {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById(id))
        // let dateTime = [];
        // let value = [];
        // if (data && data.length > 0) {
        //   data.forEach(item => {
        //     dateTime.push(item.dateTime);
        //     value.push(item.value);
        //   });
        // }
        // 绘制图表
        myChart.setOption({
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            axisLine: {
              lineStyle: {
                // 设置x轴颜色
                color: '#D2D2D2'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '20%',
            top: '10%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLine: {
              lineStyle: {
                // 设置x轴颜色
                color: '#D2D2D2'
              }
            }
          },
          series: [
            {
              name: '邮件营销',
              type: 'line',
              stack: '总量',
              data: [120, 132, 101, 134, 90, 230, 210]
            },
            {
              name: '联盟广告',
              type: 'line',
              stack: '总量',
              data: [220, 182, 191, 234, 290, 330, 310]
            },
            {
              name: '视频广告',
              type: 'line',
              stack: '总量',
              data: [150, 232, 201, 154, 190, 330, 410]
            },
            {
              name: '直接访问',
              type: 'line',
              stack: '总量',
              data: [320, 332, 301, 334, 390, 330, 320]
            },
            {
              name: '搜索引擎',
              type: 'line',
              stack: '总量',
              data: [820, 932, 901, 934, 1290, 1330, 1320]
            }
            // {
            //   data: value,
            //   type: "line",
            //   smooth: true,
            //   // areaStyle: { color: "rgba(211,98,24,1)" },
            //   itemStyle: {
            //     normal: {
            //       // 拐点上显示数值
            //       label: {
            //         show: false
            //       },
            //       borderColor: "rgba(211,98,24,1)", // 拐点边框颜色
            //       lineStyle: {
            //         //   width: 5, // 设置线宽
            //         type: "dotted" //'dotted'虚线 'solid'实线
            //       }
            //     }
            //   }
            // }
          ],
          color: ['rgba(211,98,24,1)']
        })

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      },
      // 设备类型
      deviceType_echarts(id) {
        let myChart = this.$echarts.init(document.getElementById(id))
        // 绘制图表

        myChart.setOption({
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            splitLine: { show: false },
            axisLabel: {
              show: false,
              textStyle: {
                color: 'red'
              }
            }
          },

          yAxis: {
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五'],
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            }
          },
          series: [
            {
              name: '搜索引擎',
              type: 'bar',
              stack: '总量',
              label: {
                show: true,
                position: 'insideRight'
              },
              data: [820, 832, 901, 934, 1290],
              itemStyle: {
                //通常情况下：
                normal: {
                  //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                  color: function(params) {
                    var colorList = [
                      '#65d186',
                      '#f67287',
                      '#f29e3c',
                      '#c05bdd',
                      '#f29e3c',
                      '#7a65f2',
                      '#65d186',
                      '#f67287'
                    ] //每根柱子的颜色
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        })

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      },
      // 预警类型
      warnningType() {
        let myChart = this.$echarts.init(
          document.getElementById('warnningType')
        )
        // 绘制图表
        let bottomTitle = {
          text: '262',
          textStyle: {
            color: '#fff',
            fontSize: 14,
            align: 'center'
          }
        }
        let topTitle = {
          text: '设备预警',
          textStyle: {
            fill: '#fff',
            fontSize: 12
          }
        }
        myChart.setOption({
          title: {
            text: bottomTitle.text,
            left: 'center',
            top: '52%',
            textStyle: {
              color: '#fff',
              fontSize: 14,
              align: 'center'
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '45%',
            style: {
              text: topTitle.text,
              textAlign: 'center',
              fill: '#fff',
              fontSize: '12'
              //fontWeight: 500,
            }
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              graphic: {
                type: 'text',
                left: 'center',
                top: '45%',
                style: {
                  text: 'topTitle',
                  textAlign: 'center',
                  fill: '#fff',
                  fontSize: '12'
                  //fontWeight: 500,
                }
              },
              label: {
                show: true
                //   position: "center"
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },

              data: [
                { value: 335, name: '直接访问' },
                { value: 310, name: '邮件营销' },
                { value: 234, name: '联盟广告' },
                { value: 135, name: '视频广告' },
                { value: 1548, name: '搜索引擎' }
              ]
            }
          ]
        })

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      },
      // 维保情况
      trapezoid_chart(data, proportion, dateTime) {
        let myChart = this.$echarts.init(
          document.getElementById('fix_situation_eacharts')
        )
        let option = {
          color: [
            'rgba(0,255,249,0.8)',
            'rgba(0,255,249,0.5)',
            '#193160',
            '#0F5092'
          ],
          tooltip: {
            trigger: 'item',
            formatter: function(arg) {
              console.log(arg, data, proportion, arg['dataIndex'])
              let html = dateTime[arg['dataIndex']] + '<br>'
              html +=
                arg.name.slice(0, 4) + '数:' + data[arg['dataIndex']] + '<br>'
              html +=
                arg.name.slice(0, 4) +
                '率:' +
                proportion[arg['dataIndex']] +
                '%'

              return html
            }
          },

          series: [
            {
              name: '漏斗图',
              type: 'funnel',

              left: '10%',
              top: 0,
              right: '20%',
              //x2: 80,
              bottom: 30,
              width: '85%',
              // height: {totalHeight} - y - y2,.
              min: 0,
              max: 70,
              minSize: '0%',
              maxSize: '60%',
              sort: 'descending',
              gap: 0,
              label: {
                normal: {
                  show: true,
                  position: 'inside',
                  color: '#fff'
                }
              },
              // labelLine: {
              //   length: 10,
              //   lineStyle: {
              //     width: 1,
              //     type: "solid",
              //   },
              // },
              itemStyle: {
                borderColor: '#000712',
                borderWidth: 0
              },
              emphasis: {
                label: {
                  fontSize: 10
                }
              },
              data: [
                { value: 40, name: '验收完成数' + data[0] },
                { value: 50, name: '维保处理数' + data[1] },
                { value: 60, name: '维保提交数' + data[2] },
                {
                  value: 30,
                  name: '订单111',
                  itemStyle: {
                    opacity: 0
                  },
                  label: {
                    show: false
                  },
                  tooltip: {
                    show: false
                  }
                }
              ]
            }
          ]
        }
        myChart.setOption(option)

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      },
      // 预警等级
      warnningLevel() {
        let myChart = this.$echarts.init(
          document.getElementById('warnningLevel')
        )
        // 绘制图表

        myChart.setOption({
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            top: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            splitLine: { show: false },
            axisLabel: {
              show: false,
              textStyle: {
                color: 'red'
              }
            }
          },

          yAxis: {
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五'],
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            }
          },
          series: [
            {
              name: '搜索引擎',
              type: 'bar',
              stack: '总量',
              label: {
                show: true,
                position: 'insideRight'
              },
              data: [820, 832, 901, 934, 1290],
              itemStyle: {
                //通常情况下：
                normal: {
                  //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                  color: function(params) {
                    var colorList = [
                      '#65d186',
                      '#f67287',
                      '#f29e3c',
                      '#c05bdd',
                      '#f29e3c',
                      '#7a65f2',
                      '#65d186',
                      '#f67287'
                    ] //每根柱子的颜色
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        })

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      },
      // 近24小时数据分析
      drawNearlyData() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(
          document.getElementById('Nearly_echarts')
        )

        // 绘制图表
        myChart.setOption({
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisLine: {
              lineStyle: {
                // 设置x轴颜色
                color: '#D2D2D2'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '5%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            axisLine: {
              lineStyle: {
                // 设置x轴颜色
                color: '#D2D2D2'
              }
            }
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              smooth: true,
              areaStyle: { color: 'rgba(87,247,255,0.5)' },
              itemStyle: {
                normal: {
                  // 拐点上显示数值
                  label: {
                    show: false
                  },
                  borderColor: 'rgba(87,247,255,0.5)', // 拐点边框颜色
                  lineStyle: {
                    //   width: 5, // 设置线宽
                    type: 'dotted' //'dotted'虚线 'solid'实线
                  }
                }
              }
            }
          ],
          color: ['rgba(87,247,255,1)']
        })

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  @font-face {
    font-family: 'digital';
    src: url('../views/monitor_screen/font/digital.ttf') format('truetype');
  }
  .digital {
    font-family: digital;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
  }
  .contBox {
    // transition: all 0.5s ease-in-out;
    transition: all 0.5s linear;
    height: 100%;
  }
  .text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .container {
    height: 100%;
    // 天气
    .weather {
      position: relative;
      margin-top: 10px;
      .title {
        position: absolute;
        top: -15px;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 40%;
        }
      }
      .title_bg {
        width: 100%;
      }
      .realTime {
        .des {
          position: absolute;
          right: 26px;
          top: 34px;
        }
      }
      .history {
        position: relative;
        .des {
          position: absolute;
          right: 0px;
          top: 21px;
          .status_list {
            span {
              margin-right: 20px;
            }
            .onLine {
              &::before {
                content: '';
                width: 8px;
                display: inline-block;
                height: 8px;
                background: #57f7ff;
                margin-right: 4px;
                border-radius: 1px;
              }
            }
            .fix {
              &::before {
                content: '';
                width: 8px;
                display: inline-block;
                height: 8px;
                background: #d36218;
                margin-right: 4px;
                border-radius: 1px;
              }
            }
            .outLine {
              &::before {
                content: '';
                width: 8px;
                display: inline-block;
                height: 8px;
                background: #c7c7c7;
                margin-right: 4px;
                border-radius: 1px;
              }
            }
          }
        }
      }

      .unitException {
        .flex-bet {
          display: flex;
          justify-content: space-between;
          text-align: center;

          span {
            flex: 1;
          }
        }
        .safeData {
          background: #193160;
          margin-top: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 23%;
        }
      }
    }
    // 监控
    .monitor {
      position: relative;
      margin-top: 10px;
      .title {
        position: absolute;
        top: -20px;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 34%;
        }
      }
      .flex_bet {
        display: flex;
        justify-content: space-between;
        .tab_li {
          padding: 0 28px;

          span {
            margin-right: 20px;
            cursor: pointer;
            color: #95adce;
          }

          .active {
            color: #529aff;

            &::before {
              content: '';
              display: inline-block;
              width: 8px;
              height: 8px;
              border-radius: 0px;
              background: #529aff;
              margin-right: 4px;
            }
          }

          .data-time {
            display: inline-block;
            width: 230px;
            padding: 2px 10px;
            background-color: rgba(22, 50, 113, 1);
          }
        }
      }

      .banner {
        position: relative;
        .monitor {
          position: absolute;

          .movie {
            position: absolute;
            top: -64px;
            left: 29px;
          }

          .movie_status {
            position: absolute;
            top: -104px;
            animation: rot 6s linear infinite;
            left: -3px;
          }

          img {
            float: left;
          }
        }

        .monitor_1 {
          left: 5%;
          top: 27%;
        }

        .monitor_2 {
          left: 45%;
          top: 30%;
        }

        .monitor_3 {
          left: 78%;
          top: 25%;
        }

        .monitor_4 {
          left: 250px;
        }
      }
    }

    // 施工提醒
    .workingWarm {
      height: 40%;
      margin-top: 30px;

      position: relative;
      .title {
        position: absolute;
        top: -20px;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 34%;
        }
      }
      .warmList {
        height: 32%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 0 20px;
        li {
          text-align: center;
          width: 16%;
          // opacity: 0.5;
          background: #274979;
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
      }
      .warmList_table {
        height: 67%;
        margin-top: 10px;
        .flex-bet {
          display: flex;
          justify-content: space-between;
          text-align: center;

          span {
            flex: 1;
          }
        }
        .safeData {
          background: #193160;
          margin-top: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 23%;
        }
      }
    }

    // 设备类型
    .device_type {
      position: relative;
      .des {
        position: absolute;
        right: 0px;
        top: 21px;
      }
    }
    // 绿色施工
    .green {
      position: relative;
      margin-top: 10px;
      .title {
        position: absolute;
        top: -16px;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 40%;
        }
      }
      .title_bg {
        width: 100%;
      }
      .warm_type {
        position: relative;
        .des {
          position: absolute;
          right: 26px;
          top: 17px;
        }
      }
      .warm_level {
        position: relative;
        height: 90%;
        .des {
          position: absolute;
          right: 26px;
          top: 17px;
        }
      }
    }

    .record {
      position: relative;
      margin-top: 30px;
      .title {
        position: absolute;
        top: -16px;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 40%;
        }
      }

      .title_bg {
        width: 100%;
      }
      .fix_situation {
        position: relative;
        .des {
          position: absolute;
          right: 26px;
          top: 17px;
        }
      }
      .fix_history {
        position: relative;
        .des {
          position: absolute;
          right: 0px;
          top: 17px;
          .status_list {
            span {
              margin-right: 20px;
            }
            .onLine {
              &::before {
                content: '';
                width: 8px;
                display: inline-block;
                height: 8px;
                background: #57f7ff;
                margin-right: 4px;
                border-radius: 1px;
              }
            }
            .fix {
              &::before {
                content: '';
                width: 8px;
                display: inline-block;
                height: 8px;
                background: #d36218;
                margin-right: 4px;
                border-radius: 1px;
              }
            }
            .outLine {
              &::before {
                content: '';
                width: 8px;
                display: inline-block;
                height: 8px;
                background: #c7c7c7;
                margin-right: 4px;
                border-radius: 1px;
              }
            }
          }
        }
      }
    }
  }
  p {
    // margin-bottom: 0;
    color: #d2d2d2;
  }
  li {
    list-style: none;
  }
  /deep/ .ant-calendar-picker-input {
    background-color: #163271;
  }
</style>
